import { Component, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PickerCatagory } from './category-picker.component';

@Component({
  templateUrl: 'category-picker-popup.html',
  styleUrls: ['category-picker-popup.css']
})
export class CategoryPickerPopupComponent
{
  public closeOnSelection: boolean;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public context: any,
    @Optional() private _dialogRef: MatDialogRef<CategoryPickerPopupComponent>)
  {
    this.closeOnSelection = context?.closeOnSelection;
  }

  public onCategoryPickerSelect(e: PickerCatagory)
  {
    if (this.closeOnSelection)
    {
      this._dialogRef.close(e);
    }
  }
}
