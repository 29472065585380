import { Component, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Transaction, Category } from '../../model';

@Component({
  templateUrl: 'transaction-breakdown.html',
  styleUrls: ['transaction-breakdown.css']
})
export class TransactionBreakdownComponent
{
  public category: Category;
  public transactions: Transaction[];
  public categories: Category[];

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public context: any)
  {
    this.transactions = context.transactions;
    this.categories = context.categories;
    this.category = context.category;
  }
}
