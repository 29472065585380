import { Component } from '@angular/core';

import { Subscription } from 'rxjs';
import { format, addMonths, subMonths } from 'date-fns';

import { DialogProvider } from '@slender/modals';

import { FinancialAggregate, CategoryAggregate, Category, State } from '../../model';
import { AggregationService, DataController, CategoriesService, StateService } from '../../services';
import { TransactionBreakdownComponent } from './transaction-breakdown.component';

@Component({
  templateUrl: 'budget.html',
  styleUrls: ['budget.css']
})
export class BudgetComponent
{
  public pastMonths: string[] = [];
  public currentMonth: string;
  public nextMonths: string[] = [];

  public fa: FinancialAggregate;
  public categories: Category[];
  public state: State;

  private subs: Subscription[] = [];

  constructor(
    private _categoriesService: CategoriesService,
    private _stateService: StateService,
    private _aggregationService: AggregationService,
    private _dataController: DataController,
    private _dialogProvider: DialogProvider) { }

  public ngOnInit()
  {
    this.subs.push(this._categoriesService.data$.subscribe(cs => this.categories = cs));
    this.subs.push(this._stateService.data$.subscribe(s => this.state = s));
    this.subs.push(this._aggregationService.data$.subscribe(fa => this.updateFa(fa)));
    setTimeout(() => this._dataController.runAggregates(), 1000);
  }

  private updateFa(fa: FinancialAggregate)
  {
    this.fa = fa;
    this.currentMonth = format(fa.date, "MMM");
    this.pastMonths = [];
    for (var i = 1; i < 4; i++)
    {
      this.pastMonths.push(format(subMonths(fa.date, i), "MMM"));
    }
    this.nextMonths = [];
    for (var i = 1; i < 4; i++)
    {
      this.nextMonths.push(format(addMonths(fa.date, i), "MMM"));
    }
  }

  public onSquashRollover(ca: CategoryAggregate)
  {
    ca.rollover = 0;
    ca.category.data.rollover = 0;
    this.fa.calcBalancesAndTotals();
    this._categoriesService.updateData([ca.category]);
  }

  public onBudgetChange(ca: CategoryAggregate, budget: number | string)
  {
    if (typeof budget == 'string')
    {
      budget = parseFloat(budget.replace(",", ""));
    }
    ca.budget = budget;
    ca.category.data.budget = budget;
    this.fa.calcBalancesAndTotals();
    this._categoriesService.updateData([ca.category]);
  }

  public onFundChange(ca: CategoryAggregate, fund: number | string)
  {
    if (typeof fund == 'string')
    {
      fund = parseFloat(fund.replace(",", ""));
    }
    ca.fund = fund;
    ca.category.data.fund = fund;
    this.fa.calcBalancesAndTotals();
    this._categoriesService.updateData([ca.category]);
  }

  public onRollToNextClick()
  {
    let categoriesToUpdate: Category[] = [];
    this.fa.groupAggregates.forEach(ga =>
      ga.categoryAggregates.forEach(ca =>
      {
        if (ca.category)
        {
          if (ca.category.doesAccumulate)
          {
            ca.rollover = ca.balance;
            ca.category.data.rollover = ca.balance;
          }
          else
          {
            ca.rollover = 0;
            ca.category.data.rollover = 0;
          }
          ca.fund = 0;
          ca.category.data.fund = 0;
          categoriesToUpdate.push(ca.category);
        }
      }));

    this._categoriesService.updateData(categoriesToUpdate);
    this.state.lastClosingBalance = this.fa.currentBalance; 
    this._dataController.gotoMonth(1);
    this.state.currentMonth = this.fa.date;
    this._stateService.update(this.state);

    this.fa.calcBalancesAndTotals();
  }

  public onBreakdownClick(ca: CategoryAggregate)
  {
    if (ca.type != 'category') { return; }

    this._dialogProvider.showDialog(TransactionBreakdownComponent, {
      category: ca.category,
      transactions: ca.transactions,
      categories: this.categories
    });
  }

  public ngOnDestroy()
  {
    this.subs.forEach(s => s.unsubscribe());
  }
}
