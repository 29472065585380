import { Component } from '@angular/core';
import { AsyncTaskController, AppStatus } from '@slender/foundation';

import { DataController, CategoriesService, TransactionService, StateService } from '../../services';
import { Transaction } from '../../model';
import { State } from '../../model/state';

@Component({
  templateUrl: 'layout.html',
  styleUrls: ['layout.css']
})
export class LayoutComponent
{
  public currentDate: Date;

  constructor(
    private _asyncTaskController: AsyncTaskController,
    private _dataController: DataController,
    private _cs: CategoriesService,
    private _ts: TransactionService,
    private _ss: StateService) { }

  public ngOnInit()
  {
    this._dataController.fetch();
    this._asyncTaskController.updateStatus(new AppStatus('info', `Ready`));
  }

  private onTest()
  {
    //this._cs.data$.value.forEach(c => c.data = {
    //  budget: c.budget,
    //  rollover: c.rollover,
    //  isExpectedExpense: c.isExpectedExpense
    //});
    //this._cs.updateData(this._cs.data$.value);

    //let updates: Transaction[] = [];
    //this._ts.data$.value.forEach(t =>
    //{
    //  if (t.category == "INCOME|OTHER")
    //  {
    //    t.category = "Income|Other"
    //    updates.push(t);
    //  }
    //});
    //this._ts.updateData(updates);

    //this._ss.data$.subscribe(s => console.log(s));
    //this._ss.fetch();
    //let s = new State("{}");
    //this._ss.update(s);
  }
}
