import { CategoryAggregate } from "./category-aggregate";
import { CategoryAggregateType } from "./types";

export function calcTotals(name: string, type: CategoryAggregateType, range: CategoryAggregate[]): CategoryAggregate
{
  let ca = new CategoryAggregate(name, type);

  for (let i = 0; i < ca.past.length; i++)
  {
    ca.past[i] = range.reduce((sum, ca) => sum + ca.past[i], 0);
  }
  ca.pastAvg = range.reduce((sum, ca) => sum + ca.pastAvg, 0);

  ca.rollover = range.reduce((sum, ca) => sum + Math.max(0, ca.rollover), 0);

  ca.budget = range.reduce((sum, ca) => sum + ca.budget, 0);
  ca.fund = range.reduce((sum, ca) => sum + ca.fund, 0);
  ca.actualExpense = range.reduce((sum, ca) => sum + ca.actualExpense, 0);
  ca.projectedExpense = range.reduce((sum, ca) => sum + ca.projectedExpense, 0);
  ca.balance = range.reduce((sum, ca) => sum + ca.balance, 0);

  ca.next.forEach((n, i) =>
  {
    n.actualExpense = range.reduce((sum, ca) => sum + ca.next[i].actualExpense, 0);
    n.balance = range.reduce((sum, ca) => sum + ca.next[i].balance, 0);
  });

  return ca;
}

export function getMonthNumber(date?: Date)
{
  if (!date)
  {
    date = new Date();
  }

  return ((date.getFullYear() - 2000) * 12) + date.getMonth();
}
