import { Injectable, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ConfirmationComponent } from "../components";

@Injectable()
export class DialogProvider
{
  constructor(private _dialog: MatDialog) { }

  public showDialog(component: any, data: any, options?: { size: { width: string, height: string } })
  {
    if (!options?.size)
    {
      return this.showDialogMedium(component, data);
    }
    else
    {
      let dialogRef = this._dialog.open(component, {
        width: options.size.width, height: options.size.height,
        data: data
      });

      return dialogRef;
    }
  }

  public showConfirmation(data: any, component: any = null)
  {
    if (!component)
    {
      component = ConfirmationComponent;
    }

    return this.showDialogSmall(component, data);
  }

  public confirm(title: string, commitButtonTitle: string, message: string, confirmedFn: () => void)
  {
    this.showConfirmation({ title, titleIcon: 'warn', commitButtonTitle, message })
      .afterClosed()
      .subscribe(yes =>
      {
        if (yes)
        {
          confirmedFn();
        }
      });
  }

  private showDialogMedium(component: TemplateRef<object>, data: any)
  {
    let dialogRef = this._dialog.open(component, {
      width: '60vw', height: '70vh',
      data: data
    });

    return dialogRef;
  }

  private showDialogSmall(component: TemplateRef<object>, data: any)
  {
    let dialogRef = this._dialog.open(component, {
      width: '450px', height: '200px',
      data: data
    });

    return dialogRef;
  }
}
