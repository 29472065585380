import { CategoryAggregate } from './category-aggregate';
import { calcTotals } from "./helpers";

export class GroupAggregate
{
  public name: string;
  public totals: CategoryAggregate;
  public categoryAggregates: CategoryAggregate[];

  constructor(name: string)
  {
    this.name = name;
    this.categoryAggregates = [];
  }

  public calcBalances(isMonthComplete = false)
  {
    this.categoryAggregates.forEach(ca =>
    {
      ca.calcPastAvg();
      ca.calcBalances(isMonthComplete);
    });
  }

  public calcTotals()
  {
    this.totals = calcTotals("Totals", 'group-totals', this.categoryAggregates);
  }

  public reset()
  {
    this.categoryAggregates.forEach(ca => ca.reset());
  }
}
