import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

import { RibbonToolbarComponent } from './module.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule, MatInputModule, MatButtonModule, MatMenuModule,
  ],
  declarations: [RibbonToolbarComponent],
  exports: [RibbonToolbarComponent]
})
export class RibbonToolbarModule { }
