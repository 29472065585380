import { Injectable } from '@angular/core'

import { combineLatest } from 'rxjs';

import { Broadcaster } from '@slender/core';

import { Transaction, FinancialAggregate } from '../model';
import { CategoriesService } from './categories.service';
import { TransactionService } from './transactions.service';
import { StateService } from './state.service';

@Injectable()
export class AggregationService
{
  public get data$() { return this._broadcaster; }

  public financialAggregate: FinancialAggregate;

  protected readonly _broadcaster = new Broadcaster<FinancialAggregate>(true);
  private transactions: Transaction[];

  constructor(
    private _transactionService: TransactionService,
    private _categoriesService: CategoriesService,
    private _stateService: StateService)
  {
    this._transactionService.data$.subscribe(ts => this.transactions = ts);
    combineLatest(this._stateService.data$, this._categoriesService.data$)
      .subscribe(data => this.financialAggregate = new FinancialAggregate(data[0], data[1]));
  }

  public run(date: Date)
  {
    if (this.financialAggregate)
    {
      this.financialAggregate.aggregateTransactions(this.transactions, date);
      this.financialAggregate.calcBalancesAndTotals();
      this._broadcaster.broadcast(this.financialAggregate);
    }
  }

  public runBalancesAndTotals()
  {
    if (this.financialAggregate)
    {
      this.financialAggregate.calcBalancesAndTotals();
      this._broadcaster.broadcast(this.financialAggregate);
    }
  }
}
