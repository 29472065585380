import { Observable, Subject, empty } from 'rxjs';
import { map } from 'rxjs/operators';

import { parse as parseCsv, Parser, ParseResult, ParseError, ParseMeta } from 'papaparse';

import { loadTextFile } from './file-functions';

export function loadCsvFile<T>(file: File, transformFn?: TransformFn<T>): Observable<Result<T>>
{
  return loadTextFile(file).pipe(map(data =>
  {
    return parseCsvFile(data, transformFn);
  }));
}

export function parseCsvFile<T>(data: string, transformFn?: TransformFn<T>): Result<T>
{
  let rowIndex = 0;
  let result: Result<T> = { data: [], errors: [] };

  parseCsv(data, {
    header: true,
    dynamicTyping: false,
    skipEmptyLines: true,
    step: transformFn ? (pr: ParseResult, parser: Parser) => onStep(pr, parser, transformFn, rowIndex++, result) : undefined,
    complete: !transformFn ? (pr: ParseResult) => result = pr : undefined,
    error: (err: ParseError) => { throw err; }
  });

  return result;
}

function onStep<T>(pr: ParseResult, parser: Parser, transformFn: TransformFn<T>, rowIndex: number, results: Result<T>)
{
  try
  {
    if (pr)
    {
      if (pr.errors && pr.errors.length)
      {
        results.errors = results.errors.concat(pr.errors);
      }
      if (pr.data && pr.data.length)
      {
        let value = transformFn(pr.data[0], rowIndex, pr, parser);
        if (value)
        {
          results.data.push(value);
        }
      }
      results.meta = pr.meta;
    }
  }
  catch (ex)
  {
    results.errors.push(ex);
  }
}

export type TransformFn<T> = (data: any, rowIndex: number, pr: ParseResult, parser: Parser) => T;

export interface Result<T>
{
  data: T[];
  errors: Array<ParseError | CsvValidationError | Error>;
  meta?: ParseMeta;
}

export class CsvValidationError
{
  constructor(public field: string, public message: string) { }
}
