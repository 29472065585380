import { Component, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { DialogProvider } from '@slender/modals';
import { loadTextFile, parseCsvFile, ImportGridComponent, ImportOptions, ImportItem } from "@slender/data-import";

import { Transaction, Category, Tag } from '../../model';
import { TransactionService, CategoriesService, DataController, TransactionImportProvider } from '../../services';
import { PickerCatagory } from '../categories/category-picker.component';
import { TransactionsListComponent } from './transactions-list.component';
import { AddTransactionsComponent } from './add-transactions.component';

@Component({
  templateUrl: 'transactions.html',
  styleUrls: ['transactions.css']
})
export class TransactionsComponent
{
  @ViewChild("transactionsList")
  public transactionsList: TransactionsListComponent

  public source: string;

  public transactions: Transaction[];
  public categories: Category[];

  public filter: string;
  public selectedTransaction: Transaction;

  private subs: Subscription[] = [];

  constructor(
    private _transactionService: TransactionService,
    private _categoriesService: CategoriesService,
    private _dataController: DataController,
    private _dialogProvider: DialogProvider,
    private _transactionImportProvider: TransactionImportProvider)
  { }

  public ngOnInit()
  {
    this.subs.push(this._transactionService.data$.subscribe(ts => { this.transactions = ts; }));
    this.subs.push(this._categoriesService.data$.subscribe(cs => this.categories = cs));
  }

  public onImport(e: { target: { files: File[] } })
  {
    if (e?.target?.files?.length)
    {
      this._transactionImportProvider.import(e.target.files[0], this.source,
        transactions => this._transactionService.add$(transactions)
          .subscribe(() => this._transactionService.fetch()));
    }
  }

  public applyFilter(event: Event)
  {
    this.filter = (event.target as HTMLInputElement).value?.trim().toLowerCase();
  }

  public onAddTagClick(selectedCat: string, selectedField: string, newTagText: string)
  {
    let newTag = `${selectedField}C:${newTagText}`;
    let category = this.categories.find(c => c.fullName == selectedCat)!;
    category.tags.push(new Tag(newTag));
    this._categoriesService.updateTags(category.id, category.tags.join(","));
  }

  public onAddTransactionClick()
  {
    this._dialogProvider.showDialog(AddTransactionsComponent, {
      sourceTransaction: this.selectedTransaction,
      categories: this.categories,
      type: 'add'
    }).afterClosed().subscribe((transactions: Transaction[]) =>
    {
      if (transactions?.length)
      {
        this._transactionService.add$(transactions).subscribe(() => this._transactionService.fetch())
      }
    });
  }

  public onDistributeClick()
  {
    this._dialogProvider.showDialog(AddTransactionsComponent, {
      sourceTransaction: this.selectedTransaction,
      categories: this.categories,
      type: 'distribute'
    }).afterClosed().subscribe((transactions: Transaction[]) =>
    {
      if (transactions?.length)
      {
        let distId = this.selectedTransaction.id.toString();
        this.selectedTransaction.dist = distId;
        this.selectedTransaction.data.category = "DISTRIBUTED";
        transactions.forEach((t, i) => t.dist = `${distId}-${i}`);

        this._transactionService.updateData([this.selectedTransaction]);
        this._transactionService.add$(transactions).subscribe(() => this._transactionService.fetch())
      }
    });
  }

  public onCategorizeClick()
  {
    this.transactions.sort((a, b) => b.date.valueOf() - a.date.valueOf());
    for (var transaction of this.transactions)
    {
      if (!transaction.category)
      {
        transaction.data.category = this._categoriesService.categorize(transaction)?.fullName;
        if (transaction.category)
        {
          this._transactionService.updateData([transaction]);
        }
      }
      if (transaction.id == this.selectedTransaction.id)
      {
        break;
      }
    }
    this.transactionsList.refresh();
    this._dataController.runAggregates();
  }

  public onIgnore()
  {
    if (!this.selectedTransaction) { return; }

    this.setCategory(this.selectedTransaction, "IGNORE");
  }

  public onSelect(transaction: Transaction)
  {
    this.selectedTransaction = transaction;
  }

  public onCategoryPickerSelect(e: PickerCatagory)
  {
    if (!this.selectedTransaction) { return; }

    let categoryFullName = `${e.group}|${e.name}`;
    this.setCategory(this.selectedTransaction, categoryFullName);
  }

  private setCategory(transaction: Transaction, categoryFullName: string)
  {
    transaction.data.category = categoryFullName;
    this.transactionsList.refresh();
    this._transactionService.updateData([transaction]);
    this._dataController.runAggregates();
  }

  public ngOnDestroy()
  {
    this.subs.forEach(s => s.unsubscribe());
  }
}
