export class IndexedList<TItem> //implements Iterator<TLinkedNode>
{
  public get length(): number
  {
    return this._keys.length;
  }

  private _keys: Array<number | string> = [];
  private _items: { [key: string]: any } = {};

  constructor()
  {
  }

  public push(key: number | string, item: TItem)
  {
    this._keys.push(key);
    this._items[key] = item;
  }

  public remove(key: number | string)
  {
    this._keys.splice(this._keys.indexOf(key), 1);
    delete this._items[key];
  }

  public clear()
  {
    this._keys = [];
    this._items = {};
  }

  public indexOf(key: number | string): number
  {
    return this._keys.indexOf(key);
  }

  public byKey(key: number | string): TItem
  {
    return this._items[key];
  }

  public byIndex(index: number): TItem
  {
    return this._items[this._keys[index]];
  }

  public keyByIndex(index: number): number | string
  {
    return this._keys[index];
  }

  //public count(fn: (item: TItem) => boolean): number
  //{
  //  let count = 0;

  //  let item = this.first;
  //  while (item)
  //  {
  //    if (fn(item))
  //    {
  //      count++;
  //    }

  //    item = item.next;
  //  }

  //  return count;
  //}

  //public sum(fn: (item: TItem) => number): number
  //{
  //  let sum = 0;

  //  let item = this.first;
  //  while (item)
  //  {
  //    sum += fn(item);
  //    item = item.next;
  //  }

  //  return sum;
  //}

  //public find(predicate: (item: TItem) => boolean): TItem
  //{
  //  let item = this.first;
  //  while (item && !predicate(item))
  //  {
  //    item = item.next;
  //  }

  //  return item;
  //}

  //public some(predicate: (item: TItem) => boolean): boolean
  //{
  //  let item = this.first;
  //  while (item)
  //  {
  //    if (predicate(item))
  //    {
  //      return true;
  //    }

  //    item = item.next;
  //  }

  //  return false;
  //}

  //public reduce<T>(fn: (previousValue: T, item: TItem) => T, initialValue: T, until?: (item: TItem) => boolean): T
  //{
  //  let accumulator = initialValue;

  //  let item = this.first;
  //  while (item && (!until || !until(item)))
  //  {
  //    accumulator = fn(accumulator, item);
  //    item = item.next;
  //  }

  //  return accumulator;
  //}

  //public map<T>(fn: (item: TItem) => T): T[]
  //{
  //  let mappedList = [];
  //  this.forEach(item => mappedList.push(fn(item)));

  //  return mappedList;
  //}

  //public toList(): TItem[]
  //{
  //  let list = [];

  //  let item = this.first;
  //  while (item)
  //  {
  //    list.push(item);
  //    item = item.next;
  //  }

  //  return list;
  //}

  //public reversed(): LinkedList<TItem>
  //{
  //  let item = this.last;
  //  while (item)
  //  {
  //    yield return item;
  //    item = item.previous;
  //  }
  //}

  //next(value?: any): IteratorResult<TItem> { return null; }
  //return?(value?: any): IteratorResult<TItem> { return null; }
  //throw?(e?: any): IteratorResult<TItem> { return null; }
}
