import { trigger, animate, style, transition } from '@angular/animations';

export const asyncTaskAnimation = trigger('taskAnim', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(50px)' }),
    animate(500)
  ]),
  transition(':leave', [
    animate('0.5s ease', style({
      margin: '0',
      width: '0',
      padding: '0',
      opacity: 0
    }))
  ])
])
