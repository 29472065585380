import { Injectable, TemplateRef } from '@angular/core'
import { Broadcaster } from '@slender/core';

@Injectable()
export class RibbonToolbarController
{
  public ribbonView: IRibbonToolbarView;
  public searchInput: Broadcaster<string>;

  constructor()
  {
    console.debug("RibbonToolbarController ctor");
    this.searchInput = new Broadcaster();
  }

  public attatch(ribbonView: IRibbonToolbarView)
  {
    if (!this.ribbonView)
    {
      this.ribbonView = ribbonView;
    }
    else
    {
      throw new Error("Ribbon view already attached.");
    }
  }

  public addSection(id: string, label: string, template: TemplateRef<any>, context?: any, index: number = NaN)
  {
    this.checkView();
    this.ribbonView.addSection(new RibbonToolbarSection(id, label, template, context), index);
  }

  public withSection<T>(id: string, fn: (section: RibbonToolbarSection<T>) => void)
  {
    this.checkView();
    let section = this.ribbonView.sections.find(s => s.id == id);
    if (section)
    {
      fn(section);
      this.updateView();
    }
  }

  public updateView()
  {
    this.ribbonView.runChangeDetection();
  }

  private checkView()
  {
    if (!this.ribbonView)
    {
      throw new Error("Ribbon controller has no attached view.");
    }
  }
}

export interface IRibbonToolbarView
{
  sections: RibbonToolbarSection<any>[];
  addSection(section: RibbonToolbarSection<any>, index: number): void;
  runChangeDetection(): void;
}

export class RibbonToolbarSection<T>
{
  constructor(
    public id: string,
    public label: string,
    public template: TemplateRef<any>,
    public context: T)
  { }
}
