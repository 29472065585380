import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Subscription } from 'rxjs';

import { DialogProvider } from '@slender/modals';

import { Category, Tag } from '../../model';
import { CategoriesService } from '../../services';

@Component({
  templateUrl: 'categories.html',
  styleUrls: ['categories.css']
})
export class CategoriesComponent
{
  public dataSource: MatTableDataSource<Category>;

  public selectedCategory: Category;
  public fields = ["id", "group", "name", "tags"];

  private sub: Subscription;

  constructor(
    private _categoriesService: CategoriesService,
    private _dialogProvider: DialogProvider)
  { }

  public ngOnInit()
  {
    this.dataSource = new MatTableDataSource<Category>();
    this.sub = this._categoriesService.data$.subscribe(cs => this.dataSource.data = cs);
  }

  public onRowClick(category: Category)
  {
    this.selectedCategory = category;
  }

  public onChange()
  {
    this._categoriesService.updateData([this.selectedCategory]);
  }

  public onAddTagClick(newValue: string)
  {
    this.selectedCategory.tags.push(new Tag(newValue));
  }

  public onSaveTagsClick()
  {
    this._categoriesService.updateTags(this.selectedCategory.id, this.selectedCategory.tags.join(","));
  }

  public onTagDelete(e: { index: number })
  {
    this.selectedCategory.tags.splice(e.index, 1);
  }

  public ngOnDestroy()
  {
    this.sub.unsubscribe();
  }
}
