import { Injectable, Injector } from '@angular/core'
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/cookie';

import { Observable, of as observableOf } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import sha256 from "fast-sha256";
import { TextEncoder } from 'text-encoding-shim';

import { IAuthService, Session, SessionToken, AuthConfig, User } from '../infrastructure';
import { AuthController } from '../controllers';

@Injectable()
export class AuthService implements IAuthService
{
  public currentSession: Session;
  public get isAuthenticated() { return this.currentSession.isAuthenticated; };

  public config: AuthConfig;

  private _onVerifiedFn: (seesion: Session) => void;
  private _permissionCache: { [key: string]: boolean } = {};

  private readonly COOKIE_NAME = "SlenderAuthenticationToken";

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _authController: AuthController)
  {
    this.currentSession = Session.anonymous;
    this.config = new AuthConfig();
  }

  public login(system: string, username: string, password: string): Observable<any>
  {
    return this._http.post<SessionToken>('auth/login', { system, username, password }).pipe(
      tap(sessionToken => Cookie.set(this.COOKIE_NAME, sessionToken.token)));
  }

  public verify(): Observable<boolean>
  {
    if (Cookie.get(this.COOKIE_NAME))
    {
      return this._http.get<Session>('auth/verify').pipe(
        tap(session =>
        {
          this.currentSession = session;
          this._authController.verified.broadcast(session);
        }),
        map(() => true),
        catchError(err => this.gotoLogin()));
    }
    else
    {
      return this.gotoLogin();
    }
  }

  public logout()
  {
    // $fj: Handle failure!
    this._http.get('auth/logout').subscribe();

    this.currentSession = Session.anonymous;
    this._permissionCache = {};
    Cookie.delete(this.COOKIE_NAME);

    this._authController.loggedOut.broadcast(this.currentSession);

    this.gotoLogin();
  }

  public changePassword(newPasswordHash: string): Observable<any>
  {
    return this._http.post('auth/change-password', { newPasswordHash: newPasswordHash });
  }

  public hasPermission(permission: string): boolean
  {
    if (this._permissionCache[permission])
    {
      return true;
    }

    for (let pattern of this.currentSession.permissions.split("|"))
    {
      if (new RegExp("^" + pattern.split("*").join(".*") + "$").test(permission))
      {
        this._permissionCache[permission] = true;

        return true;
      }
    }

    return false;
  }

  public createUser(user: User, password: string): Observable<any>
  {
    var newUser: any = user;
    newUser.password = password;
    return this._http.post('auth/create-user', newUser);
  }

  public saltHash(data: string): string
  {
    let uint8array = new TextEncoder("utf-8")
      .encode("$3$2lC.K7/Zeno$" + data);

    let saltedHashArray = sha256(uint8array);

    let saltedHash = "";
    for (let i = 0; i < saltedHashArray.length; i++)
    {
      saltedHash += ("0" + saltedHashArray[i].toString(16)).slice(-2);
    }

    return saltedHash;
  }

  private gotoLogin(): Observable<boolean>
  {
    this._router.navigate(['pub/login']);
    return observableOf(false);
  }
}
