import { Category } from "../category";
import { Transaction } from "../transaction";
import { ExpenseEntry, CategoryAggregateType } from "./types";

export class CategoryAggregate
{
  public category: Category;
  public type: CategoryAggregateType;
  public name: string;

  public past: number[] = [0, 0, 0];
  public pastAvg: number = 0;

  public rollover = 0;
  public budget = 0;
  public fund = 0;
  public actualExpense = 0;
  public projectedExpense = 0;
  public balance = 0;

  public next: ExpenseEntry[] = [
    { actualExpense: 0, balance: 0 },
    { actualExpense: 0, balance: 0 },
    { actualExpense: 0, balance: 0 }
  ];

  public transactions: Transaction[];

  constructor(category: Category | string, type: CategoryAggregateType)
  {
    if (category instanceof Category)
    {
      this.category = category;
      this.name = category.name;
      this.rollover = category.rollover;
      this.budget = category.budget;
      this.fund = category.fund;
    }
    else
    {
      this.name = category;
    }
    this.type = type;
    this.transactions = [];
  }

  public addTransaction(transaction: Transaction, deltaM: number)
  {
    //let lastMonth = this.transactions[this.transactions.length - 1]?.date.getMonth();
    //if (lastMonth != undefined && transaction.date.getMonth() != lastMonth)
    //{
    //  let td = transaction.date;
    //  let date = new Date(td.getFullYear(), td.getMonth(), 0, 23, 59);
    //  this.transactions.push(Transaction.blank(date));
    //}
    this.transactions.push(transaction);

    if (deltaM < 0)
    {
      this.past[-deltaM - 1] += -transaction.amount;
    }
    else if (deltaM == 0)
    {
      this.actualExpense += -transaction.amount;
    }
    else 
    {
      this.next[deltaM - 1].actualExpense += -transaction.amount;
    }
  }

  public calcPastAvg()
  {
    this.pastAvg = this.past.reduce((sum, ex) => sum + ex, 0) / 3;
  }

  public calcBalances(isMonthComplete = false)
  {
    if (this.category?.isExpectedExpense && this.actualExpense < this.budget && !isMonthComplete)
    {
      this.projectedExpense = this.budget;
    }
    else
    {
      this.projectedExpense = this.actualExpense;
    }

    this.balance = (this.rollover + this.budget + this.fund) - this.projectedExpense;

    let runningBalance = this.balance;
    this.next.forEach(next =>
    {
      next.balance = (runningBalance + this.budget) - next.actualExpense;
      runningBalance = next.balance;
    });
  }

  public reset()
  {
    for (var i = 0; i < this.past.length; i++)
    {
      this.past[i] = 0;
    }
    this.actualExpense = 0;
    this.projectedExpense = 0;
    this.next.forEach(n => n.actualExpense = 0);

    this.transactions = [];
  }
}
