import { Transaction } from "./transaction";

export class Category
{
  public id: string;

  public name: string;
  public group: string;
  public get fullName() { return `${this.group}|${this.name}`; };

  public tags: Tag[];

  public get budget(): number { return this.data.budget; }
  public get fund(): number { return this.data.fund; }
  public get rollover(): number { return this.data.rollover; }

  public get hasDynamicBudget(): boolean { return this.data.hasDynamicBudget; }
  public get isExpectedExpense(): boolean { return this.data.isExpectedExpense; }
  public get doesAccumulate(): boolean { return this.data.doesAccumulate; }

  public data: CategoryData;

  constructor(raw?: any)
  {
    this.id = raw?.id || 42;
    this.name = raw?.name;
    this.group = raw?.group;

    this.tags = raw?.tags ? (raw?.tags as string).split(",").map(t => new Tag(t)) : [];

    this.data = new CategoryData(raw?.data);
  }

  public isMatch(transaction: Transaction): boolean
  {
    return this.tags.some(t =>
    {
      let field = transaction[t.matchField];
      let isMatch =
        (t.matchType == 'starts' && field.startsWith(t.text)) ||
        (t.matchType == 'ends' && field.endsWith(t.text)) ||
        (t.matchType == 'contains' && field.includes(t.text));

      return isMatch;
    });
  }
}

export class CategoryData
{
  public budget: number;
  public fund: number;
  public rollover: number;

  public hasDynamicBudget: boolean;
  public isExpectedExpense: boolean;
  public doesAccumulate: boolean; //  public hasExcessAccrual: boolean;
  //  public hasShortfallAccrual: boolean;

  constructor(json: string)
  {
    let raw = json ? JSON.parse(json) : null;

    this.budget = raw?.budget || 0;
    this.fund = raw?.fund || 0;
    this.rollover = raw?.rollover || 0;

    this.hasDynamicBudget = raw?.hasDynamicBudget;
    this.isExpectedExpense = raw?.isExpectedExpense;
    this.doesAccumulate = raw?.doesAccumulate;
  }
}

export class Tag
{
  public matchField: 'code' | 'type' | 'description';
  public matchType: 'starts' | 'ends' | 'contains';
  public text: string;

  private _str: string;

  constructor(tag: string)
  {
    this._str = tag;

    let [match, text] = tag.split(":");
    this.matchField = match[0] == "C" ? 'code' : match[0] == "T" ? 'type' : 'description';
    this.matchType = match[1] == "S" ? 'starts' : match[1] == "E" ? 'ends' : 'contains';
    this.text = text;
  }

  public toString()
  {
    return this._str;
  }
}