import { Injectable } from "@angular/core";
import { NativeDateAdapter, MatDateFormats } from "@angular/material/core";

import { format, parse } from 'date-fns';

@Injectable()
export class ZaDateAdapter extends NativeDateAdapter
{
  public parse(value: any): Date 
  {
    return parse(value);
  }

  public format(date: Date, displayFormat: Object): string
  {
    if (typeof displayFormat == 'string')
    {
      return format(date, displayFormat);
    }
    else
    {
      return date.toDateString();
    }
  }
}

export const ZA_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  }
};

export const ZA_TIME_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD HH:mm',
  },
  display: {
    dateInput: 'YYYY-MM-DD HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  }
};
