import { LayoutComponent } from './_layout/layout.component';
export { LayoutComponent }

import { TransactionsComponent } from './transactions/transactions.component';
export { TransactionsComponent }

import { TransactionsListComponent } from './transactions/transactions-list.component';
export { TransactionsListComponent }

import { AddTransactionsComponent } from './transactions/add-transactions.component';
export { AddTransactionsComponent }

import { CategoriesComponent } from './categories/categories.component';
export { CategoriesComponent }

import { CategoryPickerComponent } from './categories/category-picker.component';
export { CategoryPickerComponent }

import { CategoryPickerPopupComponent } from './categories/category-picker-popup.component';
export { CategoryPickerPopupComponent }

import { BudgetComponent } from './budget/budget.component';
export { BudgetComponent }

import { TransactionBreakdownComponent } from './budget/transaction-breakdown.component';
export { TransactionBreakdownComponent }

export const COMPONENTS: any[] = [
  LayoutComponent,
  TransactionsComponent,
  TransactionsListComponent,
  AddTransactionsComponent,
  CategoriesComponent,
  CategoryPickerComponent,
  CategoryPickerPopupComponent,
  BudgetComponent,
  TransactionBreakdownComponent
];
