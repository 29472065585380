export class LinkedList<TLinkedNode extends ILinkedNode<TLinkedNode>> //implements Iterator<TLinkedNode>
{
  public first?: TLinkedNode;
  public last?: TLinkedNode;
  public length: number;

  public onInsert: (node: TLinkedNode) => void;
  public onRemove: (node: TLinkedNode) => void;

  constructor(nodes?: TLinkedNode[])
  {
    this.length = 0;

    if (nodes)
    {
      for (let node of nodes)
      {
        this.append(node);
      }
    }
  }

  public append(node: TLinkedNode)
  {
    if (node.previous || node.next)
    {
      throw new Error("Node is already linked.");
    }

    node.collection = this;

    if (!this.first || !this.last)
    {
      this.first = node;
      this.last = node;
    }
    else
    {
      node.linkPrevious(this.last);
      this.last.linkNext(node);
      this.last = node;
    }

    this.length++;

    if (this.onInsert) { this.onInsert(node); }
  }

  public appendList(nodes: TLinkedNode[])
  {
    for (let node of nodes)
    {
      this.append(node);
    }
  }

  public prepend(node: TLinkedNode)
  {
    if (node.previous || node.next)
    {
      throw new Error("Node is already linked.");
    }

    node.collection = this;

    if (!this.first || !this.last)
    {
      this.first = node;
      this.last = node;
    }
    else
    {
      node.linkNext(this.first);
      this.first.linkPrevious(node);
      this.first = node;
    }

    this.length++;

    if (this.onInsert) { this.onInsert(node); }
  }

  public insertBefore(existingNode: TLinkedNode, node: TLinkedNode)
  {
    if (node.previous || node.next)
    {
      throw new Error("Node is already linked.");
    }

    if (!this.first || !this.last)
    {
      this.append(node);
    }
    else if (existingNode === this.first)
    {
      this.prepend(node);
    }
    else
    {
      node.collection = this;

      let previous = existingNode.previous;
      let next = existingNode;

      node.linkPrevious(previous);
      node.linkNext(next);

      if (previous)
      {
        previous.linkNext(node);
      }
      existingNode.linkPrevious(node);

      this.length++;

      if (this.onInsert) { this.onInsert(node); }
    }
  }

  public insertAfter(existingNode: TLinkedNode, node: TLinkedNode)
  {
    if (node.previous || node.next)
    {
      throw new Error("Node is already linked.");
    }

    if (!this.first || !this.last || existingNode === this.last)
    {
      this.append(node);
    }
    else
    {
      node.collection = this;

      let previous = existingNode;
      let next = existingNode.next;

      node.linkPrevious(previous);
      node.linkNext(next);

      existingNode.linkNext(node);
      if (next)
      {
        next.linkPrevious(node);
      }

      this.length++;

      if (this.onInsert) { this.onInsert(node); }
    }
  }

  public remove(node: TLinkedNode)
  {
    if (node.previous && node.next)
    {
      node.previous.linkNext(node.next);
      node.next.linkPrevious(node.previous);
    }
    else if (node === this.first)
    {
      this.first = node.next;
      if (node.next)
      {
        node.next.linkPrevious();
      }
      else
      {
        this.last = undefined;
      }
    }
    else if (node === this.last)
    {
      this.last = node.previous;
      if (node.previous)
      {
        node.previous.linkNext();
      }
      else
      {
        this.first = undefined;
      }
    }

    node.linkPrevious();
    node.linkNext();

    this.length--;

    if (this.onRemove) { this.onRemove(node); }
  }

  public clear()
  {
    this.forEach(node => 
    {
      node.linkPrevious();
      node.linkNext();
    });

    this.first = undefined;
    this.last = undefined;

    this.length = 0;
  }

  public forEach(action: (node: TLinkedNode) => void, startNode?: TLinkedNode, until?: (node: TLinkedNode) => boolean)
  {
    let node = startNode ? startNode : this.first;
    while (node && (!until || until(node)))
    {
      let nextNode = node.next;

      action(node);

      node = nextNode;
    }
  }

  public count(fn: (node: TLinkedNode) => boolean): number
  {
    let count = 0;

    let node = this.first;
    while (node)
    {
      if (fn(node))
      {
        count++;
      }

      node = node.next;
    }

    return count;
  }

  public sum(fn: (node: TLinkedNode) => number): number
  {
    let sum = 0;

    let node = this.first;
    while (node)
    {
      sum += fn(node);
      node = node.next;
    }

    return sum;
  }

  public find(predicate: (node: TLinkedNode) => boolean): TLinkedNode | undefined
  {
    let node = this.first;
    while (node && !predicate(node))
    {
      node = node.next;
    }

    return node;
  }

  public findFromEnd(predicate: (node: TLinkedNode) => boolean): TLinkedNode | undefined
  {
    let node = this.last;
    while (node && !predicate(node))
    {
      node = node.previous;
    }

    return node;
  }

  public some(predicate: (node: TLinkedNode) => boolean): boolean
  {
    let node = this.first;
    while (node)
    {
      if (predicate(node))
      {
        return true;
      }

      node = node.next;
    }

    return false;
  }

  public reduce<T>(fn: (previousValue: T, node: TLinkedNode) => T, initialValue: T, until?: (node: TLinkedNode) => boolean): T
  {
    let accumulator = initialValue;

    let node = this.first;
    while (node && (!until || !until(node)))
    {
      accumulator = fn(accumulator, node);
      node = node.next;
    }

    return accumulator;
  }

  public map<T>(fn: (node: TLinkedNode) => T): T[]
  {
    let mappedList: T[] = [];
    this.forEach(node => mappedList.push(fn(node)));

    return mappedList;
  }

  public toList(): TLinkedNode[]
  {
    let list = [];

    let node = this.first;
    while (node)
    {
      list.push(node);
      node = node.next;
    }

    return list;
  }

  //public reversed(): LinkedList<TLinkedNode>
  //{
  //  let item = this.last;
  //  while (item)
  //  {
  //    yield return item;
  //    item = item.previous;
  //  }
  //}

  //next(value?: any): IteratorResult<TLinkedNode> { return undefined; }
  //return?(value?: any): IteratorResult<TLinkedNode> { return undefined; }
  //throw?(e?: any): IteratorResult<TLinkedNode> { return undefined; }
}

export interface ILinkedNode<TLinkedNode extends ILinkedNode<TLinkedNode>>
{
  collection: LinkedList<TLinkedNode>;

  readonly previous?: TLinkedNode;
  readonly next?: TLinkedNode;

  linkPrevious(previous?: TLinkedNode): void;
  linkNext(next?: TLinkedNode): void;

  insertBefore(node: TLinkedNode): void;
  insertAfter(node: TLinkedNode): void;
}

export class LinkedNode<TLinkedNode extends LinkedNode<TLinkedNode>> implements ILinkedNode<TLinkedNode>
{
  public collection: LinkedList<TLinkedNode>;

  public get previous(): TLinkedNode | undefined { return this._previous; }
  private _previous?: TLinkedNode;

  public get next(): TLinkedNode | undefined { return this._next; }
  private _next?: TLinkedNode;

  public linkPrevious(previous?: TLinkedNode)
  {
    this._previous = previous;
  }

  public linkNext(next?: TLinkedNode) 
  {
    this._next = next;
  }

  public insertBefore(node: TLinkedNode)
  {
    this.collection.insertBefore(<any>this, node);
  }

  public insertAfter(node: TLinkedNode)
  {
    this.collection.insertAfter(<any>this, node);
  }
}

export class LinkedNodeContainer<T> extends LinkedNode<LinkedNodeContainer<T>>
{
  public value: T;
}
