import { Component, ViewChild, Input, Optional, Inject, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Transaction, Category } from '../../model';
import { TransactionsListComponent } from './transactions-list.component';

@Component({
  templateUrl: 'add-transactions.html',
  styleUrls: ['add-transactions.css']
})
export class AddTransactionsComponent
{
  @ViewChild("transactionsList")
  public transactionsList: TransactionsListComponent

  @ViewChild("descriptionInput")
  public descriptionInput: ElementRef;

  public sourceTransaction: Transaction;
  public type: 'add' | 'distribute';

  public title: string;

  public transactions: Transaction[] = [];
  public categories: Category[];

  public selectedTransaction: Transaction;

  public descriptionValue: string = '';
  public amountValue: string = '';
  public residualAmount: number;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public context: any,
    @Optional() private _dialogRef: MatDialogRef<AddTransactionsComponent>)
  {
    this.sourceTransaction = context.sourceTransaction;
    this.categories = context.categories;
    this.type = context.type;
  }

  public ngOnInit()
  {
    this.residualAmount = this.sourceTransaction?.absAmount || 1000000;
    this.title = this.type == 'add'
      ? "Add transactions"
      : `Distribution for ${this.sourceTransaction.description}: R${this.sourceTransaction.amount.toFixed(2)}`;
  }

  public onSelect(transaction: Transaction)
  {
    this.selectedTransaction = transaction;
  }

  public onAddClick(description: string, amountString: string)
  {
    let amount = -(+amountString);
    let transaction = this.createTransaction(description, amount);
    this.transactions.push(transaction);

    this.descriptionInput.nativeElement.value = "";
    this.residualAmount += amount;
    this.amountValue = this.residualAmount > 0.1 ? this.residualAmount.toFixed(2) : "";
    this.transactionsList.refresh();
  }

  public onDeleteClick()
  {
    let index = this.transactions.findIndex(t => t === this.selectedTransaction);
    this.transactions.splice(index, 1);
    this.residualAmount -= this.selectedTransaction.amount;
    this.amountValue = this.residualAmount.toFixed(2);
    delete this.selectedTransaction;
    this.transactionsList.refresh();
  }

  public onCommitClick()
  {
    if (this.type == 'distribute')
    {
      let sum = this.transactions.reduce((acc, t) => acc + t.absAmount, 0);
      if (sum > this.sourceTransaction.absAmount)
      {
        return;
      }
    }

    this._dialogRef.close(this.transactions);
  }

  private createTransaction(description: string, amount: number)
  {
    let transaction = new Transaction();
    transaction.source = this.sourceTransaction.source;
    transaction.date = this.sourceTransaction.date;
    transaction.code = "0000";
    transaction.type = "";
    transaction.description = description;
    transaction.amount = amount;

    return transaction;
  }
}
