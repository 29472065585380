import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';

import { RibbonToolbarModule } from "@slender/ribbon-toolbar";

import { COMPONENTS } from './components';

import { ZaDateAdapter, ZA_FORMATS } from "@slender/infrastructure";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    RibbonToolbarModule
  ],
  declarations: [COMPONENTS],
  entryComponents: [COMPONENTS],
  providers: [
    { provide: DateAdapter, useClass: ZaDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: ZA_FORMATS },
  ],
  exports: [COMPONENTS]
})
export class DataImportModule { }
