import { Component, NgZone, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { Subject } from 'rxjs';

import { SystemBuildInfoService } from '@slender/foundation';
import
{
  EventController, ErrorDetails, ErrorDetailsModalProvider,
  AsyncTaskController, AppStatus, AsyncTask
} from '@slender/foundation';

import { asyncTaskAnimation } from '../animations';
import { altVersion } from './alt-version';

@Component({
  selector: 'app-statusbar',
  templateUrl: 'statusbar.html',
  styleUrls: ['statusbar.css'],
  animations: [asyncTaskAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusbarComponent
{
  public mode: 'default' | 'error' = 'default';

  public status: AppStatus;
  public tasks: AsyncTask[] = [];
  public taskErrorLog: AsyncTask[] = [];

  public appVersion: string = "development";

  public errorDetails: ErrorDetails;

  private onDestroy = new Subject<any>();

  constructor(
    private _systemBuildInfoService: SystemBuildInfoService,
    private _asyncTaskController: AsyncTaskController,
    private _eventController: EventController,
    private _errorModalProvider: ErrorDetailsModalProvider,
    private _zone: NgZone,
    private _cd: ChangeDetectorRef)
  {
    console.debug("StatusbarComponent ctor");
    this.status = new AppStatus('info', "Ready");
    this._systemBuildInfoService.get().subscribe(info => this.appVersion = info.version || altVersion);
  }

  public ngOnInit()
  {
    console.debug("StatusbarComponent init");

    this._eventController.error.subscribe(error => this.handleError(error));
    this._asyncTaskController.statusChanges.subscribeUntil(this.onDestroy, s => this.updateStatus(s));
    this._asyncTaskController.taskChanges.subscribeUntil(this.onDestroy, tasks => this.updateTasks(tasks));
  }

  private updateStatus(status: AppStatus)
  {
    this.status = status;
    this._cd.markForCheck();
  }

  private updateTasks(tasks: AsyncTask[])
  {
    this.tasks = tasks;
    this.tasks.forEach(t =>
    {
      let delay = t.isComplete && !t.error ? 500 : 3000;
      setTimeout(() => this.handleCompletedTask(t.id), delay);
    });
    this._cd.markForCheck();
  }

  private onTaskClick(id: string)
  {
    let task = this.tasks.find(t => t.id == id);
    if (!!task)
    {
      if (task.error)
      {
        this._errorModalProvider.show(task.error);
        this.removeTaskById(task.id);
      }
      else if (task.isComplete)
      {
        this.removeTaskById(task.id);
      }
    }
  }

  private onTaskClose(id: string)
  {
    let existingTask_i = this.tasks.findIndex(t => t.id == id);
    if (existingTask_i >= 0)
    {
      this.removeTask(existingTask_i);
    }
  }

  private onErrorLogItemClick(i: number)
  {
    this._errorModalProvider.show(this.taskErrorLog[i].error);
  }

  private handleCompletedTask(id: string)
  {
    let task = this.tasks.find(t => t.id == id);
    if (task)
    {
      if (task.error)
      {
        //this.status = new AppStatus('info', "Task Error");
        this.taskErrorLog.push(task);
      }
      this.removeTaskById(task.id);
    }
  }

  private removeTaskById(id: string)
  {
    let task_i = this.tasks.findIndex(t => t.id == id);
    this.removeTask(task_i);
  }

  private removeTask(index: number)
  {
    if (index >= 0)
    {
      this.tasks.splice(index, 1);
      this._cd.markForCheck();
    }
  }

  private handleError(error: ErrorDetails)
  {
    this._zone.run(() =>
    {
      this.mode = 'error';
      this.errorDetails = error;
      this._cd.markForCheck();
    });
  }

  private onErrorDetailsClick()
  {
    this.mode = 'default';
    this._errorModalProvider.show(this.errorDetails);
  }

  private onErrorDismissClick()
  {
    this.mode = 'default';
    this._cd.markForCheck();
  }

  public ngOnDestroy()
  {
    this.onDestroy.next(true);
  }
}
