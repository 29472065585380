import { Component, Input, Output, EventEmitter } from '@angular/core';

import { AuthService } from '../../services';

@Component({
  selector: 'slender-admin-panel',
  templateUrl: 'admin-panel.html',
  styleUrls: ['admin-panel.css'],
})
export class AdminPanelComponent
{
  @Output()
  public softReload = new EventEmitter<{ value: string }>();

  public userFullName: string;

  constructor(private _authService: AuthService) { }

  public ngOnInit()
  {
    this.userFullName = this._authService.currentSession.fullName;
  }

  private onAccount() { }

  private onSettings() { }

  private onSoftReload(e: any)
  {
    this.softReload.emit(e);
  }

  private onLogout()
  {
    this._authService.logout();
  }
}
