import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { RibbonToolbarController, RibbonToolbarSection, IRibbonToolbarView } from './controllers';
import { expandAnimation } from './animations';

@Component({
  selector: 'ribbon-toolbar',
  templateUrl: 'module.html',
  styleUrls: ['module.css'],
  animations: [expandAnimation],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RibbonToolbarComponent implements IRibbonToolbarView
{
  public sections: RibbonToolbarSection<any>[] = [];

  public searchState: string;
  public searchValue: string = "";

  constructor(
    private _ribbonController: RibbonToolbarController,
    private _cd: ChangeDetectorRef)
  {
    _ribbonController.attatch(this);
  }

  public addSection(section: RibbonToolbarSection<any>, index: number) 
  {
    if (this.sections.findIndex(sec => sec.id == section.id) > 0)
    {
      throw new Error(`Ribbon Section with ID ${section.id} already exists.`);
    }

    if (isNaN(index) || index >= this.sections.length)
    {
      this.sections.push(section);
    }
    else if (index > 0)
    {
      this.sections.splice(index, 0, section);
    }
  }

  public runChangeDetection()
  {
    this._cd.markForCheck();
  }

  //===================================================================================================================

  private onSearchFocus(focused: boolean)
  {
    this.searchState = focused ? 'expanded' :
      this.searchValue ? 'expanded' : '';
  }

  private onSearchInput(e: any)
  {
    this.searchValue = e.target && e.target.value;
    this._ribbonController.searchInput.broadcast(this.searchValue);
  }

  private onClearSearch()
  {
    this.searchValue = "";
    this._ribbonController.searchInput.broadcast(this.searchValue);
  }

  //private onFilterChange(event: { target: { value: string; }; })
  //{
  //  if (this.context && this.context.onFilter && this.context.data)
  //  {
  //    this.context.data.filterValue = event.target.value;
  //    this.context.onFilter(event.target.value);
  //  }
  //}

  public ngOnDestroy()
  {
    //this._ribbonController.clear();
  }
}
