export class State
{
  public currentMonth: Date;
  public lastClosingBalance: number;

  constructor(json: string)
  {
    let raw = JSON.parse(json);

    this.currentMonth = new Date(raw.currentMonth);
    this.lastClosingBalance = raw.lastClosingBalance;
  }
}

