import { Component, ViewChild, Optional, Inject, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Observable } from 'rxjs';

import { RibbonToolbarController } from '@slender/ribbon-toolbar';

import { ImportItem, ImportOptions } from '../import-item';

@Component({
  templateUrl: 'import-grid.html',
  styleUrls: ['import-grid.css'],
  providers: [RibbonToolbarController]
})
export class ImportGridComponent
{
  @ViewChild("ribbon", { static: true })
  public ribbon: TemplateRef<any>;

  @ViewChild(MatPaginator, { static: true })
  public paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  public fields: any[] = [];
  public displayFields: any[] = [];
  public dataSource: MatTableDataSource<ImportItem<any>>;
  public setLength: number = 0;
  public metaData: { [key: string]: any } = {};

  public state: any = { valid: true };

  public importOptions = new ImportOptions();

  constructor(
    private _ribbonController: RibbonToolbarController,
    @Optional() @Inject(MAT_DIALOG_DATA) public context: ImportGridComponentContext,
    @Optional() private _dialogRef: MatDialogRef<ImportGridComponent>)
  { }

  public ngOnInit()
  {
    this._ribbonController.addSection("data", "Data", this.ribbon);
    this._ribbonController.searchInput.subscribe(filterValue => this.onFilter(filterValue));

    this.context.data.subscribe((items: any[]) =>
    {
      this.fields = Object.keys(items[0]);
      this.displayFields = ['line-no', 'mode', 'status', ...this.fields];
      this.setLength = items.length;

      let importItems = this.context.process(items, this.importOptions) as ImportItem<any>[];
      this.dataSource = new MatTableDataSource(importItems);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, headerId) =>
      {
        if (headerId == 'status')
        {
          return item.status;
        }
        else if (headerId == 'mode')
        {
          return item.importMode;
        }
        else
        {
          const value = item.fields[headerId].value;
          return value && value.toLowerCase() || "";
        }
      };
      this.dataSource.filterPredicate = (item, filterValue) =>
      {
        let string = "|";
        for (let fldName in item.fields)
        {
          string += (item.fields[fldName].value + "|").toLowerCase();
        }
        return string.includes(filterValue);
      };
    });
  }

  private onModeClick(item: ImportItem<any>)
  {
    this.toggleEnable(item);
  }

  private onToggleAllowUpdates()
  {
    this.importOptions.allowUpdates = !this.importOptions.allowUpdates;
    this.toggleEnableForUpdateItems();
  }

  private toggleEnableForUpdateItems()
  {
    this.dataSource.data.forEach(item =>
    {
      if (item.importMode == 'edit')
      {
        this.toggleEnable(item);
      }
    });
  }

  private toggleEnable(item: ImportItem<any>)
  {
    item.enabled ? item.disable() : item.enable(this.importOptions);
  }

  private onFilter(value: string)
  {
    this.dataSource.filter = value.trim().toLowerCase();

    if (this.dataSource.paginator)
    {
      this.dataSource.paginator.firstPage();
    }
  }

  private onCommit()
  {
    this.context.onCommit(this.dataSource.data
      .filter(item => item.enabled && item.entity)
      .map(item => item.entity));
    this.close();
  }

  private onCancel()
  {
    this.close();
  }

  private close()
  {
    if (this._dialogRef)
    {
      this._dialogRef.close();
    }
  }
}

export type ImportGridComponentContext = {
  title: string,
  data: Observable<any[]>,
  process: (items: any[], options: ImportOptions) => ImportItem<any>[],
  onCommit: (items: any[]) => void
};
