import { Injectable } from '@angular/core'

import { addMonths, format } from 'date-fns';

import { TransactionService } from './transactions.service';
import { CategoriesService } from './categories.service';
import { AggregationService } from './aggregation.service';
import { StateService } from './state.service';

@Injectable()
export class DataController
{
  public currentDate: Date;

  constructor(
    private _transactionService: TransactionService,
    private _categoriesService: CategoriesService,
    private _statesService: StateService,
    private _aggregationService: AggregationService)
  {
    this._statesService.data$.subscribe(state => this.currentDate = state.currentMonth);
  }

  public fetch(): void
  {
    this._transactionService.fetch();
    this._categoriesService.fetch();
    this._statesService.fetch();
  }

  public runAggregates()
  {
    this._aggregationService.run(this.currentDate);
  }

  public gotoMonth(n: number)
  {
    this.currentDate = this.currentDate = addMonths(this.currentDate, n);
    this.runAggregates();
  }
}
