import { Component, ViewChild, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { addMonths, subMonths } from 'date-fns';

import { DialogProvider } from '@slender/modals';

import { Transaction, Category } from '../../model';
import { TransactionService, DataController } from '../../services';
import { PickerCatagory } from '../categories/category-picker.component';
import { CategoryPickerPopupComponent } from '../categories/category-picker-popup.component';

@Component({
  selector: "transactions-list",
  templateUrl: 'transactions-list.html',
  styleUrls: ['transactions-list.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionsListComponent
{
  @Input()
  public get transactions(): Transaction[] { return this._transactions; }
  public set transactions(value: Transaction[]) { this._transactions = value; this.refresh(); }
  private _transactions: Transaction[];

  @Input()
  public categories: Category[];

  @Input()
  public set filter(value: string) { this.dataSource && (this.dataSource.filter = value); }

  @Input()
  public showPaginator: boolean = true;

  @Input()
  public showCategoryPicker: boolean = true;

  @Output()
  public select = new EventEmitter<Transaction>();

  @ViewChild(MatPaginator, { static: true })
  public paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  public dataSource: MatTableDataSource<Transaction>;
  public selectedTransaction: Transaction;
  public fields = ["date", "code", "type", "description", "amount", "category", "deferred"];

  public specialCategories = ["Income|Salary", "Income|Other", "IGNORE"];

  public isSeparator = (i: number, t: Transaction) => { return t.category == "BLANK"; };

  constructor(
    private _transactionService: TransactionService,
    private _dataController: DataController,
    private _dialogProvider: DialogProvider,
    private _cd: ChangeDetectorRef) { }

  public ngOnInit()
  {
    this.dataSource = new MatTableDataSource<Transaction>();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.refresh();
  }

  public refresh()
  {
    if (this.dataSource)
    {
      this.dataSource.data = this._transactions;
      this._cd.markForCheck();
    }
  }

  public onRowClick(transaction: Transaction)
  {
    this.selectedTransaction = transaction;
    this.select.emit(transaction);
  }

  //public onCategoryChange(transaction: Transaction, e: any)
  //{
  //}

  public onCategoryClick()
  {
    if (!this.showCategoryPicker) { return; }

    this._dialogProvider.showDialog(CategoryPickerPopupComponent,
      { closeOnSelection: true },
      { size: { width: '300px', height: '70vh' } })
      .afterClosed().subscribe((selectedCategory: PickerCatagory) =>
      {
        if (!selectedCategory || !this.selectedTransaction) { return; }

        let categoryFullName = `${selectedCategory.group}|${selectedCategory.name}`;
        this.selectedTransaction.data.category = categoryFullName;
        this.refresh();

        if (this.selectedTransaction.id)
        {
          this._transactionService.updateData([this.selectedTransaction]);
        }

        this._dataController.runAggregates();
      });
  }

  public onDeferredDateKeyup(transaction: Transaction, e: KeyboardEvent)
  {
    let date: Date | undefined = transaction.deferredDate ? new Date(transaction.deferredDate) : new Date(transaction.date);
    if (e.code == "ArrowRight" || e.code == "ArrowUp")
    {
      date = addMonths(date, 1);
    }
    else if (e.code == "ArrowLeft" || e.code == "ArrowDown")
    {
      date = subMonths(date, 1);
    }
    else if (e.code == "Escape")
    {
      date = undefined;
    }

    transaction.data.deferredDate = date;
    this._cd.markForCheck();
  }

  public onDeferredDateChange(transaction: Transaction)
  {
    this._transactionService.updateData([transaction]);
    this._dataController.runAggregates();
  }
}
