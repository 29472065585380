export class Transaction
{
  public id: string;

  public date: Date;
  public source: string;
  public dist: string;
  public code: string;
  public type: string;
  public description: string;
  public amount: number;
  public get absAmount(): number { return Math.abs(this.amount); }
  public get isDebit(): boolean { return this.amount < 0; }
  public get isCredit(): boolean { return this.amount > 0; }

  public get category(): string | undefined { return this.data.category; }
  public get deferredDate(): Date | undefined { return this.data.deferredDate; };

  public get isIncomeSalary(): boolean { return this.category == "Income|Salary"; }
  public get isIncomeOther(): boolean { return this.category == "Income|Other"; }
  public get isNonIncome(): boolean { return !this.category?.startsWith("Income") ?? true; }
  public get isIgnored(): boolean { return this.category == "DISTRIBUTED" || this.category == "IGNORE"; }

  public data: TransactionData;

  constructor(raw?: any)
  {
    Object.assign(this, raw);

    this.date = new Date(this.date);
    this.amount = Number.parseFloat(raw?.amount);
    this.data = new TransactionData(raw?.data);
  }

  public equals(transaction: Transaction): boolean
  {
    let isEqual =
      this.date.valueOf() == transaction.date.valueOf() &&
      this.source == transaction.source &&
      this.description == transaction.description &&
      this.amount == transaction.amount;

    return isEqual;
  }

  public static blank(date: Date): Transaction
  {
    return new Transaction({
      date: date,
      data: "{\"category\": \"BLANK\"}"
    });
  }
}

export class TransactionData
{
  public category?: string;
  public deferredDate?: Date;

  constructor(json?: string)
  {
    if (!json) { return; }

    let raw = JSON.parse(json);
    this.category = raw?.category;
    this.deferredDate = raw?.deferredDate ? new Date(raw.deferredDate) : undefined;
  }
}
