import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { SlenderFoundationModule } from '@slender/foundation';
import { SlenderFlexModule } from '@slender/flex';
import { SlenderModalsModule } from '@slender/modals';
import { DataImportModule } from '@slender/data-import';

import { LayoutComponent, TransactionsComponent, CategoriesComponent, BudgetComponent, COMPONENTS } from './components';
import { SERVICES } from './services';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,

    SlenderFoundationModule,
    SlenderFlexModule,
    SlenderModalsModule,
    DataImportModule,

    RouterModule.forChild([{
      path: '', component: LayoutComponent, children: [
        { path: 'transactions', component: TransactionsComponent },
        { path: 'categories', component: CategoriesComponent },
        { path: 'budget', component: BudgetComponent },
        { path: '', redirectTo: 'transactions', pathMatch: 'full' }
      ]
    }])
  ],
  declarations: [
    COMPONENTS
  ],
  providers: [
    SERVICES
    //{ provide: LOCALE_ID, useValue: 'en-CA' }
  ]
})
export class FinmanModule { }


// Breakdown list not sorting by deferred date
