import { Category } from "../category";
import { Transaction } from "../transaction";
import { State } from "../state";
import { GroupAggregate } from "./group-aggregate";
import { CategoryAggregate } from "./category-aggregate";
import { calcTotals, getMonthNumber } from "./helpers";

export class FinancialAggregate
{
  public date: Date;
  public isMonthComplete: boolean;

  public openingBalance: number;
  public incomeSalary: number;
  public incomeOther: number;
  public currentBalance: number;
  public deferredBalance: number;
  public currentStanding: number;

  public groupAggregates: GroupAggregate[];
  public grandTotals: CategoryAggregate;

  private _uncategorized: CategoryAggregate;

  constructor(private _state: State, categories: Category[])
  {
    this.groupAggregates = [];

    let ga: GroupAggregate | undefined = undefined;
    for (let c of categories)
    {
      if (c.group == "Income")
      {
        continue;
      }

      if (!ga || c.group != ga.name)
      {
        ga = new GroupAggregate(c.group);
        this.groupAggregates.push(ga);
      }
      ga.categoryAggregates.push(new CategoryAggregate(c, 'category'));
    }

    ga = new GroupAggregate("Uncategorized");
    this._uncategorized = new CategoryAggregate("none", 'calculated');
    ga.categoryAggregates.push(this._uncategorized);

    this.groupAggregates.push(ga);
  }

  public init()
  {
    this.openingBalance = this._state.lastClosingBalance;
    this.incomeSalary = 0;
    this.incomeOther = 0;
    this.currentBalance = this.openingBalance;
    this.deferredBalance = 0;
    this.groupAggregates.forEach(ga => ga.reset());
  }

  public aggregateTransactions(transactions: Transaction[], date: Date)
  {
    this.init();
    this.date = date;
    this.isMonthComplete = getMonthNumber() > getMonthNumber(this.date);

    for (let transaction of transactions)
    {
      if (transaction.isIgnored)
      {
        continue;
      }

      let month = (date.getFullYear() * 12) + date.getMonth();
      let tDate = transaction.deferredDate || transaction.date;
      let tMonth = (tDate.getFullYear() * 12) + tDate.getMonth();
      let deltaM = tMonth - month;

      if (deltaM < -3 || deltaM > 3)
      {
        continue;
      }

      let isCurrentMonth = deltaM == 0;
      let isLastMonth = deltaM == -1;

      if (isLastMonth && transaction.isIncomeSalary)
      {
        this.incomeSalary += transaction.amount;
        this.openingBalance += transaction.amount;
        this.currentBalance += transaction.amount;
      }
      else if (isCurrentMonth)
      {
        if (transaction.isIncomeOther)
        {
          this.incomeOther += transaction.amount;
          this.currentBalance += transaction.amount;
        }
        else if (transaction.isNonIncome)
        {
          this.currentBalance += transaction.amount;
        }

        if (transaction.deferredDate && transaction.isDebit)
        {
          this.deferredBalance += transaction.amount;
        }
      }

      if (transaction.isNonIncome)
      {
        this.getCategoryAggregate(transaction.category).addTransaction(transaction, deltaM);
      }
    }
  }

  public calcBalancesAndTotals()
  {
    this.groupAggregates.forEach(ga =>
    {
      ga.calcBalances(this.isMonthComplete);
      ga.calcTotals();
    });

    this.grandTotals = calcTotals("Grand Totals", 'grand-totals', this.groupAggregates.map(ga => ga.totals));
    let expectedExpenses = this.grandTotals.projectedExpense - this.grandTotals.actualExpense;
    this.currentStanding = this.currentBalance - (this.grandTotals.rollover + expectedExpenses);
  }

  public getCategoryAggregate(fullName = "null"): CategoryAggregate 
  {
    let [g, c] = fullName.split('|');
    let group = this.groupAggregates.find(ga => ga.name == g);

    let ca = group?.categoryAggregates.find(ca => ca.name == c);
    if (!ca) { ca = this._uncategorized; }

    return ca;
  }
}
