import { Component, Output, EventEmitter } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

import { Category } from '../../model';
import { CategoriesService } from '../../services';

@Component({
  selector: "category-picker",
  templateUrl: 'category-picker.html',
  styleUrls: ['category-picker.css']
})
export class CategoryPickerComponent
{
  @Output()
  public select = new EventEmitter<PickerCatagory>();

  public groups: CategoryGroup[] = [];
  public selectedGroup: CategoryGroup | undefined;
  public recents: PickerCatagory[] = [];

  public currentPanelName: string = 'Groups';

  private sub: Subscription;

  constructor(private _categoriesService: CategoriesService) { }

  public ngOnInit()
  {
    this.sub = this._categoriesService.data$.subscribe(cs => this.groupCategories(cs));
  }

  private groupCategories(categories: Category[])
  {
    let currentGroup: CategoryGroup = { name: "", categories: [] };
    for (let c of categories)
    {
      if (!currentGroup.name || c.group != currentGroup.name)
      {
        currentGroup = { name: c.group, categories: [] };
        this.groups.push(currentGroup);
      }
      currentGroup.categories.push(c.name);
    }
  }

  public onGroupClick(group: CategoryGroup)
  {
    this.selectedGroup = group;
    this.currentPanelName = group.name;
  }

  public onCategoryClick(c: string)
  {
    this.emitCat({ group: this.selectedGroup!.name, name: c });
  }

  public onRecentClick(r: PickerCatagory)
  {
    this.emitCat(r);
  }

  public onBackClick()
  {
    this.clear();
  }

  private emitCat(cat: PickerCatagory)
  {
    this.clear();
    this.AddRecent(cat);
    this.select.emit(cat);
  }

  private clear()
  {
    this.currentPanelName = "Groups";
    delete this.selectedGroup;
  }

  private AddRecent(cat: PickerCatagory)
  {
    if (!this.recents.find(r => r.group == cat.group && r.name == cat.name))
    {
      this.recents.unshift(cat);
      if (this.recents.length > 6)
      {
        this.recents.pop();
      }
    }
  }

  public ngOnDestroy()
  {
    this.sub.unsubscribe();
  }
}

export type CategoryGroup = { name: string, categories: string[] };
export type PickerCatagory = { group: string, name: string };
