export class ImportItem<T>
{
  public fields: { [key: string]: ImportField } = {};

  public readonly lineNo: number;

  public get enabled() { return this._enabled; }
  private _enabled: boolean = false;

  public importMode: ImportMode = 'none';
  //public get importMode() { return this._importMode; }
  //private _importMode: ImportMode = 'none'

  public get status() { return this._status; }
  private _status: ValidationStatus = 'unchecked';

  public entity: T | undefined;

  constructor(data: any, lineNo: number, defaultFieldStatus?: ValidationStatus)
  {
    for (let key in data)
    {
      this.fields[key] = new ImportField(data[key], defaultFieldStatus);
    }
    this.lineNo = lineNo;
  }

  public enable(options?: ImportOptions)
  {
    if (this.importMode != 'none' &&
      (this.importMode != 'edit' || (options && options.allowUpdates)) &&
      (this.status == 'valid' || this.status == 'warning'))
    {
      this._enabled = true;
    }
  }

  public disable()
  {
    this._enabled = false;
  }

  public validate(fieldName?: string)
  {
    if (fieldName)
    {
      let field = this.fields[fieldName] as ImportField;
      if (field.status == 'unchecked' || field.status == 'pending')
      {
        field.status = 'valid';
      }
    }
    else 
    {
      this._status = 'valid';
      for (let fldName in this.fields)
      {
        if (this.fields[fldName].status == 'invalid')
        {
          this._status = 'invalid';
          break;
        }
        else if (this.fields[fldName].status == 'warning')
        {
          this._status = 'warning';
        }
        else if (this._status == 'valid' && this.fields[fldName].status == 'pending')
        {
          this._status = 'pending';
        }
      }
    }
  }

  public pending(fieldName: string)
  {
    let field = this.fields[fieldName] as ImportField;
    if (field.status == 'valid' || field.status == 'unchecked')
    {
      field.status = 'pending';
    }
  }

  public warn(fieldName: string, warning: string)
  {
    if (this._status != 'invalid')
    {
      this._status = 'warning';
    }

    let field = this.fields[fieldName] as ImportField;
    if (field.status != 'invalid')
    {
      field.status = 'warning';
    }
    field.warnings.push(warning);
  }

  public invalidate(fieldName: string, error: string)
  {
    this._status = 'invalid';
    this._enabled = false;

    let field = this.fields[fieldName] as ImportField;
    field.status = 'invalid';
    field.errors.push(error);
  }
};

export class ImportField
{
  public value?: string;
  public status: ValidationStatus;
  public warnings: string[] = [];
  public errors: string[] = [];

  public get statusDescription()
  {
    let message: string;
    if (this.status == 'unchecked')
    {
      message = "Ignored";
    }
    else if (this.status == 'pending')
    {
      message = "Pending validation";
    }
    else
    {
      message = [this.errors.join(", "), this.warnings.join(", ")]
        .filter(x => !!x)
        .join(" | ");
    }

    return message;
  }

  constructor(value?: string, status?: ValidationStatus)
  {
    this.value = value;
    this.status = status ?? 'unchecked';
  }
}

export class ImportOptions
{
  public allowUpdates: boolean = false;
}

export type ImportMode = 'none' | 'create' | 'edit';
export type ValidationStatus = 'unchecked' | 'pending' | 'valid' | 'warning' | 'invalid';
