import { Observable, Subject, empty } from 'rxjs';

export function loadTextFile(file: File): Observable<string>
{
  let sub = new Subject<string>();
  if (file && file instanceof File)
  {
    let reader = new FileReader();
    reader.onload = e =>
    {
      try
      {
        let data = e.target && (e.target as FileReader).result as string;
        if (data)
        {
          sub.next(data);
        }
        sub.complete();
      }
      finally
      {
        sub.complete();
      }
    }
    reader.readAsText(file);
  }
  else
  {
    return empty();
  }

  return sub.asObservable();
}
