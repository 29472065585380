import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';

import { Broadcaster } from '@slender/core';

import { Category, Transaction, CategoryData } from '../model';
import { ApiResponse } from './api-response';
import { map } from 'rxjs/operators';

@Injectable()
export class CategoriesService
{
  public get data$() { return this._broadcaster; }
  protected readonly _broadcaster = new Broadcaster<Category[]>(true);

  constructor(private _http: HttpClient) { }

  public fetch(): void
  {
    this._http.get<ApiResponse<any[]>>("data/categories")
      .pipe(map(res => res.data.map(data => new Category(data))))
      .subscribe(data => this._broadcaster.broadcast(data));
  }

  public add(categories: Category[])
  {
    return this._http.post("data/categories", categories).subscribe();
  }

  public updateData(categories: Category[])
  {
    this._http.post("data/categories/data", categories.map(c => ({ id: c.id, data: c.data }))).subscribe();
  }

  public updateTags(id: string, tags: string)
  {
    this._http.post("data/categories/" + id + "?tags=true", { tags }).subscribe();
  }

  public delete(id: string) { }

  public categorize(transaction: Transaction): Category | undefined
  {
    return this._broadcaster.value
      .filter(c => c.tags)
      .find(c => c.isMatch(transaction));
  }
}
