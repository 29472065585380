import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { format, subMonths } from 'date-fns';

import { Broadcaster } from '@slender/core';

import { Transaction, TransactionData } from '../model/transaction';
import { ApiResponse } from './api-response';

@Injectable()
export class TransactionService
{
  public get data$() { return this._broadcaster; }
  protected readonly _broadcaster = new Broadcaster<Transaction[]>(true);

  constructor(private _http: HttpClient) { }

  public fetch(): void
  {
    let currentMonthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let fromDate = format(subMonths(currentMonthStart, 4), "YYYY-MM-DD");

    this._http.get<ApiResponse<any[]>>("data/transactions?from=" + fromDate)
      .pipe(map(res => res.data.map(data => new Transaction(data))))
      .subscribe(data => this._broadcaster.broadcast(data));
  }

  public add$(transactions: Transaction[]): Observable<any>
  {
    return this._http.post("data/transactions", transactions);
  }

  public updateData(transactions: Transaction[])
  {
    this._http.post("data/transactions/data", transactions.map(t => ({ id: t.id, data: t.data }))).subscribe();
  }

  public delete$(id: string, doDelete: boolean): Observable<object>
  {
    return this._http.post(`data/transactions/${id}?delete=${doDelete}`, null);
  }
}
