import { registerLocaleData } from '@angular/common';
import localeEnCA from '@angular/common/locales/en-CA';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import 'zone.js';

import { SlenderBootModule } from '@slender/foundation';
import { SlenderPlatformModule } from '@slender/platform';
import { FinmanModule } from './app.module';

enableProdMode();

registerLocaleData(localeEnCA, 'en-CA');

let platformModule = SlenderPlatformModule({ lazyModule: FinmanModule });
let bootModule = SlenderBootModule({ lazyModule: platformModule });

platformBrowserDynamic().bootstrapModule(bootModule);
