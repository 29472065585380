export class LinkedCollection<TLinkableItem extends ILinkableItem<TLinkableItem>>
{
  public get first() { return this.terminator.link.next; }
  public get last() { return this.terminator.link.previous; }
  public terminator: TLinkableItem;
  public length: number;

  constructor(items?: TLinkableItem[])
  {
    this.length = 0;
    this.terminator = new Terminator() as TLinkableItem;

    if (items)
    {
      this.appendList(items);
    }
  }

  public appendList(items: TLinkableItem[])
  {
    for (let item of items)
    {
      this.prependTo(this.terminator, item);
    }
  }

  public append(item: TLinkableItem)
  {
    this.prependTo(this.terminator, item);
  }

  public prepend(item: TLinkableItem)
  {
    this.apendTo(this.terminator, item);
  }

  public prependTo(existingItem: TLinkableItem, item: TLinkableItem)
  {
    //if (newItem.link.previous || newItem.link.next)
    //{
    //  throw new Error("Item is already linked.");
    //}

    item.link.collection = this;

    item.link.setPrevious(existingItem.link.previous);
    item.link.setNext(existingItem);
    existingItem.link.previous.link.setNext(item);
    existingItem.link.setPrevious(item);

    this.length++;
  }

  public apendTo(existingItem: TLinkableItem, item: TLinkableItem)
  {
    //if (newItem.link.previous || newItem.link.next)
    //{
    //  throw new Error("Item is already linked.");
    //}

    item.link.collection = this;

    item.link.setPrevious(existingItem);
    item.link.setNext(existingItem.link.next);
    existingItem.link.next.link.setPrevious(item);
    existingItem.link.setNext(item);

    this.length++;
  }

  public removeByLink(link: ItemLink<TLinkableItem>)
  {
    link.previous.link.setNext(link.next);
    link.next.link.setPrevious(link.previous);

    link.clear();

    this.length--;
  }

  public clear()
  {
    this.forEach(item => item.link.clear());
    this.terminator.link.clear();
    this.length = 0;
  }

  public forEach(action: (item: TLinkableItem) => void, startItem?: TLinkableItem, until?: (item: TLinkableItem) => boolean)
  {
    let item = startItem ? startItem : this.terminator.link.next;
    while (item !== this.terminator && (!until || until(item)))
    {
      let nextItem = item.link.next;

      action(item);

      item = nextItem;
    }
  }

  public count(fn: (item: TLinkableItem) => boolean): number
  {
    let count = 0;

    let item = this.terminator.link.next;
    while (item !== this.terminator)
    {
      if (fn(item))
      {
        count++;
      }

      item = item.link.next;
    }

    return count;
  }

  public sum(fn: (item: TLinkableItem) => number): number
  {
    let sum = 0;

    let item = this.terminator.link.next;
    while (item !== this.terminator)
    {
      sum += fn(item);
      item = item.link.next;
    }

    return sum;
  }

  public find(predicate: (item: TLinkableItem) => boolean): TLinkableItem | null
  {
    let item = this.terminator.link.next;
    while (item !== this.terminator && !predicate(item))
    {
      item = item.link.next;
    }

    return item;
  }

  public findFromEnd(predicate: (item: TLinkableItem) => boolean): TLinkableItem | null
  {
    let item = this.terminator.link.previous;
    while (item !== this.terminator && !predicate(item))
    {
      item = item.link.previous;
    }

    return item;
  }

  public some(predicate: (item: TLinkableItem) => boolean): boolean
  {
    let item = this.terminator.link.next;
    while (item !== this.terminator)
    {
      if (predicate(item))
      {
        return true;
      }

      item = item.link.next;
    }

    return false;
  }

  public reduce<T>(fn: (previousValue: T, item: TLinkableItem) => T, initialValue: T, until?: (item: TLinkableItem) => boolean): T
  {
    let accumulator = initialValue;

    let item = this.terminator.link.next;
    while (item !== this.terminator && (!until || !until(item)))
    {
      accumulator = fn(accumulator, item);
      item = item.link.next;
    }

    return accumulator;
  }

  public map<T>(fn: (item: TLinkableItem, index: number) => T): T[]
  {
    let mappedList: T[] = [];
    let index = 0;
    this.forEach(item => mappedList.push(fn(item, index++)));

    return mappedList;
  }

  public toList(): TLinkableItem[]
  {
    let list = [];

    let item = this.terminator.link.next;
    while (item !== this.terminator)
    {
      list.push(item);
      item = item.link.next;
    }

    return list;
  }

  //public reversed(): LinkedList<TLinkedItem>
  //{
  //  let item = this.last;
  //  while (item)
  //  {
  //    yield return item;
  //    item = item.previous;
  //  }
  //}

  //next(value?: any): IteratorResult<TLinkedItem> { return null; }
  //return?(value?: any): IteratorResult<TLinkedItem> { return null; }
  //throw?(e?: any): IteratorResult<TLinkedItem> { return null; }
}

export class Terminator implements ILinkableItem<Terminator>
{
  public link: ItemLink<Terminator>;
  constructor()
  {
    this.link = new ItemLink<Terminator>(this);
  }
}

export interface ILinkableItem<T extends ILinkableItem<T>>
{
  link: ItemLink<T>;
}

export class ItemLink<T extends ILinkableItem<T>>
{
  public item: T;

  public collection: LinkedCollection<T> | null;

  public get isFirst() { return this.collection && this._previous === this.collection.terminator; }
  public get isLast() { return this.collection && this._next === this.collection.terminator; }

  public get previous(): T { return this._previous; }
  private _previous: T;

  public get next(): T { return this._next; }
  private _next: T;

  public setPrevious(previous: T)
  {
    this._previous = previous;
  }

  public setNext(next: T) 
  {
    this._next = next;
  }

  public insertBefore(item: T)
  {
    if (this.collection)
    {
      this.collection.prependTo(this.item, item);
    }
  }

  public insertAfter(item: T)
  {
    if (this.collection)
    {
      this.collection.apendTo(this.item, item);
    }
  }

  public withdraw()
  {
    if (this.collection)
    {
      this.collection.removeByLink(this);
    }
  }

  public clear()
  {
    this._next = this.item;
    this._previous = this.item;
  }

  constructor(item: T)
  {
    this.item = item;
    this._next = item;
    this._previous = item;
  }
}
