import { Injectable } from '@angular/core'

import { map } from 'rxjs/operators';

import { DialogProvider } from '@slender/modals';
import { ImportItem, ImportGridComponent, ImportOptions, loadTextFile, parseCsvFile } from '@slender/data-import';

import { Transaction } from '../model';
import { TransactionService } from './transactions.service';

@Injectable()
export class TransactionImportProvider
{
  public transactions: Transaction[];

  constructor(
    private _dialogProvider: DialogProvider,
    private _transactionService: TransactionService)
  {
    this._transactionService.data$.subscribe(ts => { this.transactions = ts; });
  }

  public import(file: File, source: string, onCommit: (transactions: Transaction[]) => void): void
  {
    let csv$ = loadTextFile(file).pipe(map(data =>
    {
      if (source != "kk")
      {
        data = "hist,date,charge,amount,type,description,code,-\n" + data;
      }
      return parseCsvFile<any>(data);
    })).pipe(map(r => r.data));

    this._dialogProvider.showDialog(ImportGridComponent, {
      title: "Import Transactions",
      data: csv$,
      process: (dataRows: any[], options?: ImportOptions): ImportItem<Transaction>[] =>
      {
        return dataRows.map((row, i) =>
        {
          let item = new ImportItem<Transaction>(row, i + 2, 'valid');
          item.importMode = 'create';
          if (row.hist == "HIST")
          {
            let transaction = new Transaction();
            let rowDate = row.date as string;
            let formedRowDate = `${rowDate.slice(0, 4)}-${rowDate.slice(4, 6)}-${rowDate.slice(6, 8)}`; // 20191127
            transaction.date = new Date(formedRowDate);
            transaction.source = source;
            transaction.code = row.code;
            transaction.type = row.type;
            transaction.description = row.description;
            transaction.amount = Number.parseFloat(row.amount);

            if (this.transactions.some(t => t.equals(transaction)))
            {
              item.invalidate("hist", "Transaction already exist.");
            }
            else if (transaction.type.includes("OUTSTANDING CARD AUTHORISATION"))
            {
              item.warn("hist", "Transaction not authorised yet.");
            }
            else
            {
              item.validate();
              item.enable(options);
              item.entity = transaction;
            }
          }
          else if (source == "kk")
          {
            let transaction = new Transaction();
            transaction.date = new Date(row["Transaction Date"]);
            transaction.source = source;
            transaction.code = "001";
            transaction.type = "KOOPKRAG";
            transaction.description = row["Shop"];
            transaction.amount = -Number.parseFloat(row["Amount"]);

            if (this.transactions.some(t => t.equals(transaction)))
            {
              item.invalidate("Transaction Date", "Transaction already exist.");
            }
            else
            {
              item.validate();
              item.enable(options);
              item.entity = transaction;
            }
          }

          return item;
        });
      },
      onCommit: onCommit
    });
  }
}
