import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { Broadcaster } from '@slender/core';

import { State } from '../model/state';
import { ApiResponse } from './api-response';

@Injectable()
export class StateService
{
  public get data$() { return this._broadcaster; }
  protected readonly _broadcaster = new Broadcaster<State>(true);

  constructor(private _http: HttpClient) { }

  public fetch(): void
  {
    this._http.get<ApiResponse<any>>("data/state")
      .pipe(map(res => new State(res.data.data)))
      .subscribe(data => this._broadcaster.broadcast(data));
  }

  public update(state: State)
  {
    this._http.post("data/state", state).subscribe();
  }
}
