export class Debug
{
  public static enable = true;

  private static _infoLog: string = "";
  private static _errorLog: string = "";

  private static _loggers: { [key: string]: any } = {};

  public static create(name: string)
  {
    Debug._loggers[name] = "";
  }

  public static info(info: string)
  {
    Debug._infoLog += info;
  }

  public static error(error: string)
  {
    Debug._errorLog += error;
  }

  public static dump(loggerName?: string)
  {
    if (Debug.enable)
    {
      if (loggerName)
      {
        if (Debug._loggers[loggerName])
        {
          console.debug(Debug._loggers[loggerName]);
        }
      }
      else
      {
        if (Debug._errorLog)
        {
          console.debug(Debug._errorLog);
        }

        if (Debug._infoLog)
        {
          console.debug(Debug._infoLog);
        }
      }

      Debug.clear(loggerName);
    }
  }

  public static clear(loggerName?: string)
  {
    if (loggerName)
    {
      if (Debug._loggers[loggerName])
      {
        Debug._loggers[loggerName] = "";
      }
    }
    else
    {
      Debug._infoLog = "";
      Debug._errorLog = "";
    }
  }
}