import { addDays, subDays, differenceInDays, startOfToday } from 'date-fns';

export type DateRangeFieldType = 'from' | 'to' | 'delta';

export interface IDateRange
{
  from: Date;
  to: Date;
}

export class DateRange implements IDateRange
{
  public get from(): Date { return this._from; }
  public set from(value: Date)
  {
    this._from = value;
    this.updateFieldGiven('from');
  }
  private _from: Date;

  public get to(): Date { return this._to; }
  public set to(value: Date)
  {
    this._to = value;
    this.updateFieldGiven('to');
    this._toEndOf = subDays(this._to, 1);
  }
  private _to: Date;

  public get toEndOf(): Date { return this._toEndOf; }
  public set toEndOf(value: Date) { this.to = addDays(value, 1); }
  private _toEndOf: Date;

  public get delta(): number { return differenceInDays(this._to, this._from); }

  public maxRange: number = 90;

  constructor(from?: Date | string, to?: Date | string)
  {
    if (from)
    {
      this._from = new Date(from);
    }
    else
    {
      this._from = startOfToday();
    }
    
    if (to)
    {
      this._to = new Date(to);
      this.updateFieldGiven('from');
      this._toEndOf = subDays(this._to, 1);
    }
    else
    {
      this._to = addDays(this.from, 1);
    }
  }

  public updateFieldGiven(field: DateRangeFieldType)
  {
    let delta = differenceInDays(this._to, this._from);
    if (delta < 1)
    {
      switch (field)
      {
        case 'from':
          this._to = addDays(this._from, 1);
          break;
        case 'to':
          this._from = subDays(this._to, 1);
          break;
      }
    }
    else if (delta > this.maxRange)
    {
      switch (field)
      {
        case 'from':
          this._to = addDays(this._from, this.maxRange);
          break;
        case 'to':
          this._from = subDays(this._to, this.maxRange);
          break;
      }
    }
  }

  public calcField(field: DateRangeFieldType, delta = 1)
  {
    switch (field)
    {
      case 'from':
        this.from = subDays(this._to, delta);
        break;
      case 'to':
        this.to = addDays(this._from, delta);
        break;
    }
  }

  public valid()
  {
    return !!this._from && !!this._to;
  }

  public asIDateRange(): IDateRange
  {
    return { from: this.from, to: this.to };
  }

  public toJSON(): string
  {
    return JSON.stringify(this.asIDateRange());
  }

  public static from(data: { from: string, to: string }): IDateRange
  {
    if (data && data.from && data.to)
    {
      return { from: new Date(data.from), to: new Date(data.to) };
    }
    else
    {
      return new DateRange().asIDateRange();
    }
  }
}
